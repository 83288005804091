.about {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;

  .row1, .row2 {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;


    .bild1 {
      background: url("../images/about/about1_v2.jpg") center no-repeat;
      background-size: cover;
      width: 100%;
      height: 400px;
      max-width: 400px;
      max-height: 400px;
      border: solid 1px $gold;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }

    .bild2 {
      background:  url("../images/about/about2_v2.jpg") center no-repeat  ;
      background-size: cover;
      width: 100%;
      height: 400px;
      max-width: 400px;
      max-height: 400px;
      border: solid 1px $gold;
      margin-right: 1rem;
    }

    .text1, .text2 {
      display: flex;
      padding: 1rem;
      justify-content: space-evenly;
      flex-direction: column;
      text-align: center;
      letter-spacing: .5px;
      line-height: 1.5rem;
      width: 100%;
      height: 400px;
      max-width: 400px;
      max-height: 400px;
      border: solid 1px $gold;
      color: $gold;

      h1 {
        margin-bottom: 1rem;
      }
    }
  }


}

@media only screen and (max-width: 700px) {
  .about {
    padding: 1rem;

    .row1 {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;

      .bild1 {

        align-self: center;
        margin: 1rem;
      }

      .text1, .text2 {
        padding: 1rem;

      }
    }

    .row2 {
      flex-direction: column-reverse;
      display: flex;
      justify-content: center;
      align-items: center;

      .bild2 {
        margin: 1rem;
        align-self: center;
      }
    }
  }
}
.bold{
  font-weight: bold;
}