.empfehlung {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: $bild_schleier_hell,  url("../images/empfhelung/empfehlung1.jpg") center no-repeat;
  background-size: cover;
 margin-bottom: 3rem;

  h1 {
    color: $gold;
    font-size: 3rem;
    margin-top: 1rem;
  }

  h2,h3,h4 {
    color: $gold;
    margin-top: 1rem;
    font-size: 2rem;
    margin-bottom: 2rem;
    padding: .2rem;
    font-weight: normal;
    max-width: 900px;
  }
  h2{
    margin-bottom: 0.5rem;
  }
  h4{
    font-size: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    .felder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      overflow: hidden;
      width: 100%;
      max-width: 1000px;
      flex-wrap: wrap;
      background: rgba(white, 0.2);
      box-shadow: 2px 2px 2px 2px rgba($gold, 0.5);


      .spalte1, .spalte2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        max-width: 500px;
        padding: 2rem;


        .feld {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          margin: .5rem 0;
          text-align: start;

          textarea {
            height: 150px;
            font-size: 1.3rem;
            color: black;
            padding: 0 1rem;
            background: rgba(white, .2);
            border: solid 1px $gold;
          }


          input[type=text], select {
            height: 3rem;
            font-size: 1.3rem;
            color: black;
            padding: 0 1rem;
            background: rgba(white, .2);
            border: solid 1px $gold;
            border-radius: 1px;
            margin-right: 1rem;
            background-size: 5px 5px,
            5px 5px,
            1.5em 1.5em;

          }

          label {
            color: $gold;
            margin: .5rem 0;
            font-size: 1.3rem;
            margin-right: 1rem;


          }

        }
      }
    }
    button {
      background: $gold;
      width: 100%;
      max-width: 700px;
      border: solid 1px white;
      color: white;
      font-size: 1.3rem;
      padding: 1rem;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  .empfehlung{
    h1{
      font-size: 2.2rem;
    }
    h2{
      font-size: 1.5rem;
    }
    h3{
      font-size: 1.4rem;
    }
  }
  button{
    margin: 0 1rem;
  }
}
@media only screen and (max-width: 400px) {
  .empfehlung{
    h1{
      font-size: 2rem;
    }
    h2{
      font-size: 1.5rem;
    }
    h3{
      font-size: 1rem;
      padding: 0.5rem;
    }
  }


}