.topbar{
  .topbar_image{
    background:  $bild_schleier_dunkel, url("../images/lupe.jpg") no-repeat center;
    background-size: cover;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    //scroll-snap-align: start;
    font-size: 1rem;


    h1{
      margin: 1rem;
      padding: .2rem;
      font-size: 2.2rem;
      background: rgba(black,0.9);

    }


  }

}

#gold{
  color: $gold;

}

@media only screen and (max-width: 500px) {
  .topbar {
    .topbar_image {
        font-size: 0.7rem;
    }
  }
}
