.header{
  background: $bild_schleier, url("../images/schluessel.jpg") center no-repeat;
  background-size: cover;
  height: calc(90vh - 3rem);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1{
    font-size: 3rem;
  }
  h2{
    font-size: 1.7rem;
  }
  h3{
    font-size: 1.6rem;
  }

  h1,h2,h3{
    text-align: center;
    width: 100%;
    margin: 2rem 0;
  }
  h2,h3{
    font-weight: lighter;
  }
}

@media only screen and (max-width: 800px) {
    .header{
      h1{
        font-size: 2.2rem;
      }
      h2{
        font-size: 1.5rem;
      }
      h3{
        font-size: 1.4rem;
      }
    }
}
@media only screen and (max-width: 400px) {
  .header{
    h1{
      font-size: 1.4rem;
    }
    h2{
      font-size: 1rem;
    }
    h3{
      font-size: 1rem;
    }
  }
}