.formPersoen {
  display: none;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  background: rgba(white, 0.2);
  padding: 5rem;
  animation: formAnimationOpen 0.3s ease-in-out;
  box-shadow: 2px 2px 2px 2px rgba($gold,0.5);



  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;


    .feld {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: .5rem 0;
      text-align: start;


      input[type=text], select {
        height: 3rem;
        font-size: 1.3rem;
        color: black;
        padding: 0 1rem;
        background: rgba(white, .2);
        border: solid 1px $gold;
        border-radius: 1px;
        margin-right: 1rem;
        background-size: 5px 5px,
        5px 5px,
        1.5em 1.5em;

      }

      label {
        color: $gold;
        margin: .5rem 0;
        font-size: 1.3rem;
        margin-right: 1rem;


      }
    }
  }

  .datenschutz{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 80%;
    label{
      margin: 1rem ;
      font-size: 1.2rem;

    }
    input[type=checkbox]{
      align-self: center;
      justify-self: flex-start;
      height: 2rem;

      & > div{
        height: 500px;
      }


    }


  }
}