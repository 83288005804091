.formUpload {
  display: none;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  background: rgba(white, 0.2);
  box-shadow: 2px 2px 2px 2px rgba($gold,0.5);

  padding: 2rem;
  min-height: 500px;
  animation: formAnimationOpen 0.3s ease-in-out;


  .felder {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .feld {
      display: flex;
      flex-direction: column;
      margin: 1rem;

      label {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        border: $gold 1px solid;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 1rem;



      }

      input[type=file] {
        font-size: 1rem;
      }
    }
  }
}