.navigation {
  position: sticky;
  top: 0;
  .menu {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    background: white;
    position: sticky;

    li {
      padding: 1rem 1.5rem;
      top: 0;

      a {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1.3rem;
        color: $gold;

      }

      &:hover {
        background: rgba(black, .1);
      }
    }


  }
.scrollnav{
//  animation: menuAnimation 5s ease-in-out;

  ul {
    //position: fixed;
    background: rgba(black, .7);
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;

    li{
      a{
        color: white!important;
      }
    }
  }
}
  .scrollul {
    //position: fixed;
    background: rgba(black, .7) !important;
    width: 100%;
    top: 0;
    align-items: center;

  }

  .active {
    background: $gold !important;

    a {
      color: white !important;

    }

  }

  .sidemenuContainer {
    display: none;

  }
}


@media only screen and (max-width: 1100px) {
  .navigation {
    position: sticky;
    top: 0;
    .menu {
      display: none;
    }

    .sidemenuContainer {
      background: white;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;


      .sideMenu {
        display: none;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        right: -150%;
        top: 3rem;
        position: absolute;
        height: fit-content;
        padding: 1rem 0;
        background: rgb(204, 153, 51);
        background: linear-gradient(217deg, rgba(204, 153, 51, 0.6235011990407674) 0%, rgba(204, 153, 51, 1) 40%);
        list-style: none;
        transition: .5s ease-in-out;


        & .open {
          right: 0;
        }

        & .close {
          right: -150%;
        }

        & .disable {
          display: none;
        }

        & .enable {
          display: flex;
        }

        .placeButton {
          margin: 2rem;
          display: flex;
          background: white;
          width: 50%;
          padding: 1rem;
          box-shadow: 2px 2px 2px 2px grey;

          &:active {
            transform: translate(3px, 3px);
          }

          p {
            margin-right: 1rem;
          }


        }

        li {
          margin: 0.5rem 2rem;
          display: flex;
          background: white;
          width: 80%;
          box-shadow: 2px 2px 2px 2px rgba(black, .3);
          padding: 0.5rem;

          a {
            text-decoration: none;
            color: $gold;
            text-align: center;
            width: 100%;
            font-size: 1.2rem;
          }

        }
      }
    }
  }
}