.angebot {
  background: $bild_schleier_dunkel, url("../images/suche.jpg") no-repeat center;
  color: $gold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: 100%;


  h1 {
    margin: 1rem;
    font-size: 2rem;
    text-align: center;
  }

  h3 {
    font-size: 1.2rem;
    text-align: center;
  }

  .iconbar {
    display: flex;
    justify-content: space-around;
    width: 80%;
    color: white;
    margin: 2rem 0;
    max-width: 1000px;

    hr {
      color: $gold;
      border-style: dotted none none none;
      border-width: 5px;
      width: 10%;
      align-self: center;
    }
  }

  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2{
      margin-top: 2rem;
      text-align: center;
    }

    .buttonbar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      flex-wrap: wrap;
      width: 80%;


      button {
        background: $gold;
        width: 100%;
        border: solid 1px white;
        color: white;
        margin-right: 1rem;
        font-size: 1.3rem;
        padding: 1rem;
        max-width: 300px;
        margin-bottom: 5rem;


      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .angebot {
    form{
      .buttonbar{
        button{
          width: fit-content !important;
        }
      }
    }
  }
}
