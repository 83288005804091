.formPreis {
  display: none;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  background: rgba(white, 0.2);
  box-shadow: 2px 2px 2px 2px rgba($gold,0.5);
  padding: 5rem 0;

  animation: formAnimationOpen 0.3s ease-in-out;




  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;


    .feld {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: .5rem 0;
      text-align: start;


      input[type=text], select {
        height: 3rem;
        font-size: 1.3rem;
        color: black;
        padding: 0 1rem;
        background: rgba(white, .2);
        border: solid 1px $gold;
        border-radius: 1px;
        margin-right: 1rem;
        background-size: 5px 5px,
        5px 5px,
        1.5em 1.5em;

      }

      label {
        color: $gold;
        margin: .5rem 0;
        font-size: 1.3rem;
        margin-right: 1rem;


      }
    }
  }


}