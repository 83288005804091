.kontakt {
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  background: $bild_schleier_hell; // url("../images/schluessel.gif") 90% no-repeat
  background-size: cover;



  .kontakt_feld {
    width: 80%;
    max-width: 80%;
    height: 80%;
    margin: 2rem;
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: rgba(white, .2);

    h1 {
      color: $gold;
      text-align: center;
      width: 100%;
      padding: 1rem;
    }

    .row {
      width: 80%;
      height: 80%;
      align-self: center;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;


      .spalte1 {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        padding: 1rem;
        width: 100%;
        flex-wrap: wrap;


        .info {
          display: flex;
          justify-content: center;
          flex-direction: row;
          margin: 1rem 0;
          align-self: flex-start;

          img {
            height: 40px;
          }

          .text {
            margin-left: 1rem;

            .ueberschrift {
              color: $gold;
              font-size: 1.5rem;
            }

            .information {
              color: $gold;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  hr {
    width: 80%;
    margin: 1rem;
    align-self: center;
    color: $gold;
  }

  .footertext {
    display: flex;
    max-width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: $gold;
    width: 80%;
    align-self: center;
    padding: 1rem;
    background: rgba(white, .2);


    details {
      width: fit-content;
      transition: 0.5s ease-in-out;
    }

    summary {
      width: 40%;
      outline: none;
      padding: 1rem;
      display: block;
      color: white;
      padding-left: 2.2rem;
      position: relative;
      cursor: pointer;
    }

    details {
      box-sizing: border-box;
      margin-top: 5px;
      background: rgba($gold, 0.7);
      //padding: 1rem;
      color: white;

      p {
        line-height: 1.4rem;
        margin-top: 0.2rem;
      }
    }

    .inhalt {
      padding: 1rem;
    }

    details summary::-webkit-details-marker {
      display: none;
      width: 100%;
    }

    details[open] > summary:before {
      transform: rotate(90deg);
    }

    summary:before {
      content: '';
      border-width: .4rem;
      border-style: solid;
      border-color: transparent transparent transparent #fff;
      position: absolute;
      top: 1.3rem;
      left: 1rem;
      transform: rotate(0);
      transform-origin: .2rem 50%;
      transition: .25s transform ease;
    }


  }

  .copy {
    color: $gold;
    text-align: center;
    margin: 2rem;
  }

}

@media only screen and (max-width: 800px) {

  .kontakt {


    .kontakt_feld {


      .row {


        .spalte1 {
          justify-content: flex-start;
          margin: .2rem;
        }
      }
    }
  }


  .footertext {

    details {
      width: 300px;

      summary {
        max-width: 250px;
        width: 250px;
      }
    }
  }
}