@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //font-family: "Baskerville", sans-serif;
  //scroll-snap-type: y mandatory;
  //font-family: "Times New Roman", "Helvetica Neue", "fantasy", sans-serif;
  font-family: 'PT Sans', sans-serif;
}

html {


}

body {
  //height: 100vh;
  //overflow-y: scroll ;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

h1 {
  font-family: "Baskerville", sans-serif;

}

$bild_schleier: linear-gradient(rgba(0, 0, 0, .76), rgba(0, 0, 0, .76));
$bild_schleier_hell: linear-gradient(90deg, rgba(0, 0, 0, .9) 70%, rgba(94, 94, 94, .9));
$bild_schleier_dunkel: linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85));
//$gold: #CC9933;
$gold: #8b7742;

@keyframes formAnimationOpen {
  from {
    opacity: .4;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes menuAnimation {
  from {
    opacity: 0;
  }
  from {
    opacity: 1;
  }
}


@import "topbar";
@import "navigation";
@import "header";
@import "zielgruppe";
@import "angebot";
@import "formDatenImmobilie";
@import "formAusstattungImmobilie";
@import "formUpload";
@import "formPreis";
@import "formPersoenlicheDaten";
@import "about";
@import "empfehlung";
@import "kontakt";


