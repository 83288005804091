.formAusstattungImmobilie {
  display: none;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  background: rgba(white,0.2);
  animation: formAnimationOpen 0.3s ease;
  box-shadow: 2px 2px 2px 2px rgba($gold,0.5);



  .feldreihe {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    & > .feld {
      margin: .5rem 0;
      padding: 1rem;
      //border: #CC9933 solid 1px;

      textarea {
        max-width: 100%;
        resize: none;
        min-height: 100px;
        color: black;
        font-size: 1.2rem;
        padding: .2rem;
        background: rgba(white,.3);
      }

      & > label {
        margin: 1rem 0;
        //font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        width: 100%;
      }
    }
  }

  .feld2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 150px;
    text-align: start;


    input[type=text] {
      height: 2rem;
      font-size: 1.1rem;
      color: black;
      padding: 0 0.2rem;
      background: rgba(white,.2);
      border: solid 1px $gold;
      border-radius: 1px;
      margin-right: 1rem;
      margin-bottom: 1rem;
      background-size:
              5px 5px,
              5px 5px,
              1.5em 1.5em;

    }

    label {
      margin: 1rem 0;
      //font-weight: bold;
      font-size: 1.2rem;
      text-align: start;
      width: 100%;


    }

  }




  .radioBar {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;


    input[type="radio"] {
      display: none;
    }

    label {
      text-align: center;
      margin-right: 2rem;
      margin-top: 1rem;
      padding: .5rem 1.6rem;
      font-size: 1.1rem;
     background: rgba(white, .2);


    }

    input[type="radio"]:checked + label {
      background: $gold;
      color: white;
    }

    input[type="radio"]:disabled + label {
      background: grey;
      text-decoration: line-through;
      color: black;
    }
  }


}