.formDatenImmobilie {
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: center;
  animation: formAnimationOpen 0.3s ease-in-out;
  transition: 5s ease-in-out;
  .felder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    flex-wrap: wrap;
    background: rgba(white,0.2);
    box-shadow: 2px 2px 2px 2px rgba($gold,0.5);



    .spalte1, .spalte2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      max-width: 500px;
      padding: 2rem;


      .feld {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: .5rem 0;
        text-align: start;


        input[type=text], select {
          height: 3rem;
          font-size: 1.3rem;
          color: black;
          padding: 0 1rem;
          background: rgba(white,.2);
          border: solid 1px $gold;
          border-radius: 1px;
          margin-right: 1rem;
          background-size:
                  5px 5px,
                  5px 5px,
                  1.5em 1.5em;

        }

        label {
          color: $gold;
          margin: .5rem 0;
          font-size: 1.3rem;
          margin-right: 1rem;


        }

      }
    }
  }

  .radioBar {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    input[type="radio"] {
      display: none;
    }

    label {
      text-align: center;
      border: solid 1px $gold;
      width: 50%;
      padding: 0.2rem 0;
      background: rgba(white,.2);


      img {
        height: 40px;
        margin: 0.2rem;
      }

      .tooltip {
        font-size: 1.3rem !important;
      }

    }

    input[type="radio"]:checked + label {
      background: $gold;
      color: white;
    }

    input[type="radio"]:disabled + label {
      background: grey;
      text-decoration: line-through;
      color: black;
    }
  }

}