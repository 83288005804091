.zielgruppe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: $gold;
  margin: 2rem;
  //scroll-snap-align: start;


  h1 {
    text-transform: uppercase;
    font-weight: 400;
    margin: 2rem 0;
    font-size: 3rem;
  }

  .objekt-reihe {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;


    .objekt {
      margin: 1rem;

      p{
        font-size: 2rem;
        font-weight: 400;
        margin-top: 1rem;
      }

      .container {
        height: 300px;
        width: 300px;
        background: white;
        display: flex;
        justify-content: center;
        margin: 1rem 0;
        box-shadow: $gold 2px 2px 2px 2px;


        img {
          height: 70%;
          align-self: center;
        }
      }
    }
  }
}